import { motion } from 'framer-motion';
import { useState } from 'react';
import SEO from '../components/SEO';
import mindflowImage from '../assets/mindflow-ai.png';
import mindflowImage2 from '../assets/mindflow-ai2.png';
import mindflowImage3 from '../assets/mindflow-ai3.png';
import rocketDiariesImage1 from '../assets/rocketdiaries.png';
import rocketDiariesImage2 from '../assets/rocketdiaries2.png';
import rocketDiariesImage3 from '../assets/rocketdiaries3.png';
import claraImage1 from '../assets/clara.png';
import claraImage2 from '../assets/clara2.png';
import claraImage3 from '../assets/clara3.png';
import prodigyImage4 from '../assets/prodigy4.png';
import prodigyImage1 from '../assets/prodigy.png';
import prodigyImage2 from '../assets/prodigy2.png';
import prodigyImage3 from '../assets/prodigy3.png';

const Portfolio = () => {
  const [currentImageIndices, setCurrentImageIndices] = useState({});
  const [videoHeight, setVideoHeight] = useState('56.25%'); // 16:9 aspect ratio

  const projects = [
    {
      title: "MindFlow AI",
      subtitle: "Dream & Thought Interpreter",
      description: "An innovative mobile application combining classical psychological theory with cutting-edge AI to provide personalized dream and thought interpretations.",
      images: [mindflowImage, mindflowImage2, mindflowImage3],
      type: "mobile",
      features: [
        "Dream Analysis with AI-powered interpretations",
        "Thought Processing for meaningful insights",
        "AI-generated artistic visualizations",
        "Voice narration with high-quality synthesis",
        "Personal journey tracking with calendar interface"
      ],
      tags: ["iOS", "AI", "Mental Health", "React Native", "OpenAI"],
    },
    {
      title: "Rocket Diaries",
      subtitle: "SpaceX Launch Tracker",
      description: "A dynamic web application that brings space exploration to life by tracking SpaceX launches in real-time, providing comprehensive mission details and historical data.",
      images: [rocketDiariesImage1, rocketDiariesImage2, rocketDiariesImage3],
      type: "web",
      features: [
        "Live Launch Tracking with real-time updates",
        "Historical Launch Data archive",
        "Comprehensive Mission Details",
        "Interactive Data Visualization",
        "Responsive Cross-platform Design"
      ],
      tags: ["React", "SpaceX API", "Data Viz", "Real-time", "Web App", "AI"],
      link: "https://rocketdiaries.com"
    },
    {
      title: "Dubai Based - Startup",
      subtitle: "Enterprise Financial Operations Platform",
      description: "A comprehensive platform that streamlines company operations, equity management, and financial workflows across multiple jurisdictions, enabling businesses to scale globally with ease.",
      images: [claraImage1, claraImage2, claraImage3],
      type: "web",
      features: [
        "Automated Financial Operations Management",
        "Advanced Equity & Cap Table Solutions",
        "Smart Document Management System",
        "Global Banking Integration",
        "Multi-jurisdiction Compliance Engine"
      ],
      tags: ["FinTech", "Enterprise", "Global", "React", "AI", "Banking"],
    },
    {
      title: "New York Based - Startup",
      subtitle: "AI-Powered Investment Platform",
      description: "A revolutionary investment platform that democratizes access to institutional-grade opportunities through AI-driven analysis and automation, enabling investors from over 35 countries to participate in premium investment opportunities.",
      images: [prodigyImage4, prodigyImage1, prodigyImage2, prodigyImage3],
      type: "web",
      features: [
        "AI-Powered Investment Analysis & Recommendations",
        "Real-time Portfolio Management",
        "Automated Documentation & Compliance",
        "Multi-currency Support for Global Investors",
        "Interactive Analytics Dashboard"
      ],
      tags: ["FinTech", "React", "Node.js", "Investment", "Analytics"],
    },
    {
      title: "The Assemblage Community",
      subtitle: "Community-Driven Coworking Platform & Mobile App",
      description: "A comprehensive technology ecosystem built for New York City's innovative coworking and coliving spaces, integrating community engagement, workspace management, and wellness programs through a mobile app and web platform.",
      videoId: "239682621",
      type: "web",
      features: [
        "Mobile App for Community Engagement",
        "Workspace Booking & Management System",
        "Events & Wellness Program Platform",
        "Member Directory & Networking Tools",
        "Integrated Payment & Subscription Management"
      ],
      tags: ["Community", "PropTech", "Mobile", "Web", "React", "Node.js"],
    }
  ];

  const nextImage = (projectIndex) => {
    setCurrentImageIndices(prev => ({
      ...prev,
      [projectIndex]: ((prev[projectIndex] || 0) + 1) % projects[projectIndex].images.length
    }));
  };

  const prevImage = (projectIndex) => {
    setCurrentImageIndices(prev => ({
      ...prev,
      [projectIndex]: prev[projectIndex] === 0 
        ? projects[projectIndex].images.length - 1 
        : (prev[projectIndex] || 0) - 1
    }));
  };

  const getVimeoEmbed = (videoId) => {
    return `https://player.vimeo.com/video/${videoId}?background=1&autoplay=1&loop=1&byline=0&title=0`;
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <SEO 
        title="Portfolio - Iteration One"
        description="Explore our innovative AI solutions and cutting-edge platform development projects."
      />
      
      <div className="container mx-auto px-4 py-20">
        <h1 className="text-4xl md:text-5xl font-bold mb-16 text-center">
          Our <span className="text-[#42DCDB]">Portfolio</span>
        </h1>

        <div className="grid grid-cols-1 gap-16">
          {projects.map((project, index) => (
            <motion.div
              key={project.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="neon-card overflow-hidden group"
            >
              <div className="flex flex-col lg:flex-row items-center gap-12 p-8">
                {/* Image/Video Container */}
                <div className="relative w-full lg:w-[580px] flex-shrink-0">
                  {project.type === 'mobile' ? (
                    // Mobile App Display
                    <div className="relative mx-auto w-[320px]">
                      {/* Mobile navigation buttons */}
                      <button onClick={() => prevImage(index)} className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-12 z-10 text-[#42DCDB] hover:text-white transition-colors">
                        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                      </button>
                      <button onClick={() => nextImage(index)} className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-12 z-10 text-[#42DCDB] hover:text-white transition-colors">
                        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                      </button>

                      <div className="p-4">
                        <div className="absolute inset-0 bg-gradient-to-b from-[#42DCDB]/10 to-transparent rounded-[3rem]" />
                        <div className="relative aspect-[9/19.5] rounded-[2.5rem] overflow-hidden border-[12px] border-gray-800 shadow-xl">
                          <motion.img
                            key={currentImageIndices[index] || 0}
                            src={project.images[currentImageIndices[index] || 0]}
                            alt={`${project.title} screenshot ${(currentImageIndices[index] || 0) + 1}`}
                            className="object-cover w-full h-full"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3 }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    // Web Project Display
                    <div className="relative w-full">
                      {project.type === 'web' && (
                        <div className="relative w-full">
                          {project.videoId ? (
                            <div 
                              className="relative w-full rounded-xl overflow-hidden border border-gray-800 shadow-xl bg-black"
                              style={{ paddingBottom: videoHeight }}
                            >
                              <iframe
                                src={getVimeoEmbed(project.videoId)}
                                className="absolute top-0 left-0 w-full h-full"
                                frameBorder="0"
                                allow="autoplay; fullscreen"
                                title={`${project.title} video`}
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                allowFullScreen
                              />
                            </div>
                          ) : (
                            // Web Image Display
                            <>
                              <button onClick={() => prevImage(index)} className="absolute left-4 top-1/2 -translate-y-1/2 z-10 text-[#42DCDB] hover:text-white transition-colors">
                                <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                                </svg>
                              </button>
                              <button onClick={() => nextImage(index)} className="absolute right-4 top-1/2 -translate-y-1/2 z-10 text-[#42DCDB] hover:text-white transition-colors">
                                <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                </svg>
                              </button>

                              <div className="rounded-xl overflow-hidden border border-gray-800 shadow-xl">
                                <motion.img
                                  key={currentImageIndices[index] || 0}
                                  src={project.images[currentImageIndices[index] || 0]}
                                  alt={`${project.title} screenshot ${(currentImageIndices[index] || 0) + 1}`}
                                  className="w-full h-auto"
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  transition={{ duration: 0.3 }}
                                />
                              </div>
                            </>
                          )}

                          {/* Dots Indicator (only show for images, not video) */}
                          {!project.videoId && project.images?.length > 0 && (
                            <div className="flex justify-center gap-2 mt-4">
                              {project.images.map((_, i) => (
                                <button
                                  key={i}
                                  onClick={() => setCurrentImageIndices(prev => ({ ...prev, [index]: i }))}
                                  className={`w-2 h-2 rounded-full transition-colors ${
                                    i === (currentImageIndices[index] || 0) ? 'bg-[#42DCDB]' : 'bg-gray-600 hover:bg-[#42DCDB]/50'
                                  }`}
                                />
                              ))}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* Content Container */}
                <div className="flex-1 max-w-2xl">
                  <h3 className="text-3xl font-bold mb-3 text-[#42DCDB]">
                    {project.title}
                  </h3>
                  <p className="text-xl text-gray-400 mb-3">{project.subtitle}</p>
                  <p className="text-gray-300 mb-6 text-lg">{project.description}</p>
                  
                  <div className="mb-8">
                    <h4 className="text-xl font-semibold mb-4 text-[#42DCDB]">Key Features:</h4>
                    <ul className="space-y-2 text-gray-300">
                      {project.features.map((feature, i) => (
                        <li key={i} className="flex items-start">
                          <span className="text-[#42DCDB] mr-2">•</span>
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="flex flex-wrap gap-2">
                    {project.tags.map((tag) => (
                      <span
                        key={tag}
                        className="px-4 py-1.5 text-sm bg-[#42DCDB]/10 text-[#42DCDB] rounded-full"
                      >
                        {tag}
                      </span>
                    ))}
                    {project.link && (
                      <a
                        href={project.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-4 py-1.5 text-sm bg-[#42DCDB] text-black rounded-full hover:bg-[#6DE7E6] transition-colors"
                      >
                        Visit Project
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}

          {/* Many More Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: projects.length * 0.1 }}
            className="text-center py-12"
          >
            <h3 className="text-3xl md:text-4xl font-bold text-[#42DCDB] mb-4">
              And Many More...
            </h3>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              These are just a few highlights from our portfolio. Contact us to learn more about our other successful projects and how we can help bring your vision to life.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio; 