import { Helmet } from 'react-helmet-async';

const SEO = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title} | Iteration One</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={`${title} | Iteration One`} />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={`${title} | Iteration One`} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
};

export default SEO; 