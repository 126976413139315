import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from './Logo';
import MobileMenu from './MobileMenu';

const Navbar = () => {
  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-black/80 backdrop-blur-md border-b border-[#42DCDB]/10">
      <div className="container mx-auto px-4 sm:px-6">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-3">
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              <Logo />
            </motion.div>
            <span className="text-2xl font-bold text-[#42DCDB] text-glow">
              Iteration One
            </span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/ai-lab" className="text-white hover:text-[#42DCDB] transition-colors">
              AI Lab
            </Link>
            <Link to="/services" className="text-white hover:text-[#42DCDB] transition-colors">
              Services
            </Link>
            <Link to="/portfolio" className="text-white hover:text-[#42DCDB] transition-colors">
              Portfolio
            </Link>
            <Link to="/contact" className="text-white hover:text-[#42DCDB] transition-colors">
              Contact
            </Link>
            <Link to="/start-project">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-6 py-2 rounded-full bg-[#42DCDB] text-black font-semibold hover:bg-[#42DCDB]/90 transition-colors"
              >
                Get Started
              </motion.button>
            </Link>
          </div>

          {/* Mobile Menu */}
          <MobileMenu />
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 