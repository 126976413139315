import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO';

const ThankYou = () => {
  return (
    <div className="bg-black text-white pt-24 min-h-screen">
      <SEO 
        title="Thank You"
        description="Thank you for submitting your project details. We'll be in touch soon!"
      />
      
      <div className="container mx-auto px-4 sm:px-6 py-32">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto text-center"
        >
          <motion.div
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            className="text-6xl mb-8"
          >
            🎉
          </motion.div>
          <h1 className="text-4xl sm:text-5xl font-bold mb-6">
            Thank You for <span className="text-[#42DCDB] text-glow">Reaching Out</span>
          </h1>
          <p className="text-xl text-gray-400 mb-12">
            We've received your project details and will review them shortly. 
            Expect to hear from us within the next 24-48 hours.
          </p>
          <Link to="/">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-4 rounded-full bg-[#42DCDB] text-black font-semibold hover:bg-[#42DCDB]/90 transition-colors"
            >
              Back to Home
            </motion.button>
          </Link>
        </motion.div>
      </div>
    </div>
  );
};

export default ThankYou; 