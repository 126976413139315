import { motion, useScroll, useTransform } from 'framer-motion';
import { useState, useRef } from 'react';
import { supabase } from '../lib/supabaseClient';
import SEO from '../components/SEO';

const contactInfo = {
  email: "info@iterationone.tech",
  address: {
    city: "Lewes",
    state: "Delaware",
    zip: "19958"
  },
  socials: {
    linkedin: "https://www.linkedin.com/company/40845919"
  }
};

const Contact = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end start"]
  });

  const y1 = useTransform(scrollYProgress, [0, 1], [0, 100]);
  const y2 = useTransform(scrollYProgress, [0, 1], [0, -100]);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');

    try {
      const { error } = await supabase
        .from('contacts')
        .insert([formData]);

      if (error) throw error;

      setStatus('success');
      setFormData({ name: '', email: '', company: '', message: '' });
      
      // Reset success message after 5 seconds
      setTimeout(() => setStatus(''), 5000);
    } catch (error) {
      console.error('Error:', error);
      setStatus('error');
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div ref={containerRef} className="bg-black text-white pt-24">
      <SEO 
        title="Contact Us"
        description="Get in touch with us to discuss your AI project needs"
      />
      
      <section className="relative py-20 overflow-hidden">
        {/* Animated background */}
        <div className="absolute inset-0">
          <motion.div 
            className="absolute inset-0"
            animate={{
              backgroundPosition: ['0% 0%', '100% 100%'],
              transition: {
                duration: 15,
                repeat: Infinity,
                repeatType: 'reverse'
              }
            }}
            style={{
              backgroundImage: 'radial-gradient(circle at center, #42DCDB 0.5px, transparent 0.5px)',
              backgroundSize: '30px 30px',
              opacity: 0.1
            }}
          />
        </div>

        <div className="container mx-auto px-4 sm:px-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-5xl mx-auto"
          >
            <div className="text-center mb-16">
              <motion.div
                initial={{ scale: 0.95 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                className="inline-block mb-6"
              >
                <span className="text-[#42DCDB] text-6xl">💬</span>
              </motion.div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
                Let's <span className="text-[#42DCDB] text-glow">Connect</span>
              </h1>
              <p className="text-lg sm:text-xl text-gray-400">
                Ready to transform your business with AI? We'd love to hear from you.
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-5 gap-12">
              {/* Contact Information */}
              <div className="lg:col-span-2 space-y-8">
                <div className="neon-card p-8">
                  <h3 className="text-xl font-semibold text-[#42DCDB] mb-6">
                    Contact Information
                  </h3>
                  <div className="space-y-6">
                    <a 
                      href={`mailto:${contactInfo.email}`}
                      className="flex items-center gap-4 text-gray-400 hover:text-[#42DCDB] transition-colors group"
                    >
                      <div className="p-3 rounded-lg bg-[#42DCDB]/10 group-hover:bg-[#42DCDB]/20 transition-colors">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                      </div>
                      <span>{contactInfo.email}</span>
                    </a>

                    <div className="flex items-start gap-4 text-gray-400">
                      <div className="p-3 rounded-lg bg-[#42DCDB]/10">
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      </div>
                      <div>
                        <p>{contactInfo.address.street}</p>
                        <p>{contactInfo.address.city}, {contactInfo.address.state}</p>
                        <p>{contactInfo.address.zip}</p>
                      </div>
                    </div>

                    <div className="flex gap-4">
                      <a 
                        href={contactInfo.socials.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="p-3 rounded-lg bg-[#42DCDB]/10 text-gray-400 hover:text-[#42DCDB] hover:bg-[#42DCDB]/20 transition-colors"
                      >
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                        </svg>
                      </a>
                      <p className="flex items-center text-gray-400 hover:text-[#42DCDB] transition-colors">LinkedIn</p>
                    </div>
                      
                  </div>
                </div>
              </div>

              {/* Contact Form */}
              <div className="lg:col-span-3">
                <motion.form
                  onSubmit={handleSubmit}
                  className="neon-card p-8"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div>
                      <label className="block text-[#42DCDB] text-sm font-semibold mb-2">
                        Name
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="w-full px-4 py-3 rounded-lg bg-black/30 border border-[#42DCDB]/20 text-white focus:border-[#42DCDB] focus:ring-1 focus:ring-[#42DCDB] transition-colors"
                        placeholder="John Doe"
                      />
                    </div>
                    <div>
                      <label className="block text-[#42DCDB] text-sm font-semibold mb-2">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="w-full px-4 py-3 rounded-lg bg-black/30 border border-[#42DCDB]/20 text-white focus:border-[#42DCDB] focus:ring-1 focus:ring-[#42DCDB] transition-colors"
                        placeholder="john@company.com"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-[#42DCDB] text-sm font-semibold mb-2">
                      Company
                    </label>
                    <input
                      type="text"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                      className="w-full px-4 py-3 rounded-lg bg-black/30 border border-[#42DCDB]/20 text-white focus:border-[#42DCDB] focus:ring-1 focus:ring-[#42DCDB] transition-colors"
                      placeholder="Your Company"
                    />
                  </div>

                  <div>
                    <label className="block text-[#42DCDB] text-sm font-semibold mb-2">
                      Message
                    </label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      rows="5"
                      className="w-full px-4 py-3 rounded-lg bg-black/30 border border-[#42DCDB]/20 text-white focus:border-[#42DCDB] focus:ring-1 focus:ring-[#42DCDB] transition-colors resize-none"
                      placeholder="Tell us about your project..."
                    />
                  </div>

                  <motion.button
                    type="submit"
                    whileHover={{ scale: 1.02 }}
                    className="w-full px-8 py-4 rounded-lg bg-[#42DCDB] text-black font-semibold hover:bg-[#42DCDB]/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={status === 'sending'}
                  >
                    {status === 'sending' ? 'Sending...' : 'Send Message'}
                  </motion.button>

                  {status === 'success' && (
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="text-green-400 text-center"
                    >
                      Message sent successfully! We'll get back to you soon.
                    </motion.p>
                  )}

                  {status === 'error' && (
                    <motion.p
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="text-red-400 text-center"
                    >
                      Something went wrong. Please try again later.
                    </motion.p>
                  )}
                </motion.form>
              </div>
            </div>
          </motion.div>
        </div>

        {/* Floating elements */}
        <motion.div
          style={{ y: y1 }}
          className="hidden md:block absolute top-20 -left-20 w-72 h-72 parallax-layer"
        >
          <div className="w-full h-full rounded-full bg-[#42DCDB]/5 blur-3xl" />
        </motion.div>
        <motion.div
          style={{ y: y2 }}
          className="hidden md:block absolute bottom-20 -right-20 w-72 h-72 parallax-layer"
        >
          <div className="w-full h-full rounded-full bg-[#42DCDB]/5 blur-3xl" />
        </motion.div>
      </section>
    </div>
  );
};

export default Contact; 