import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-black text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <h3 className="text-xl font-bold mb-4">Iteration One</h3>
            <p className="text-gray-400 mb-4">
              Building innovative AI solutions and cutting-edge platforms.
            </p>
            <div className="flex space-x-4">
              <a 
                href="https://www.linkedin.com/company/40845919" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-[#42DCDB] hover:text-[#6DE7E6]"
              >
                LinkedIn
              </a>
            </div>
          </div>

          {/* Services */}
          <div>
            <h3 className="text-xl font-bold mb-4">Services</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/services" className="text-gray-400 hover:text-[#42DCDB]">
                  vCTO Services
                </Link>
              </li>
              <li>
                <Link to="/ai-lab" className="text-gray-400 hover:text-[#42DCDB]">
                  AI Solutions
                </Link>
              </li>
              <li>
                <Link to="/ai-lab" className="text-gray-400 hover:text-[#42DCDB]">
                  Platform Development
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-400 hover:text-[#42DCDB]">
                  Consulting
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources */}
          <div>
            <h3 className="text-xl font-bold mb-4">Resources</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/portfolio" className="text-gray-400 hover:text-[#42DCDB]">
                  Portfolio
                </Link>
              </li>  
            </ul>
          </div>

          {/* Contact */}
          <div>
            <h3 className="text-xl font-bold mb-4">Contact</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/contact" className="text-gray-400 hover:text-[#42DCDB]">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/start-project" className="text-gray-400 hover:text-[#42DCDB]">
                  Get Started
                </Link>
              </li>
              <li>
                <a 
                  href="mailto:contact@iteration-one.com"
                  className="text-gray-400 hover:text-[#42DCDB]"
                >
                  info@iterationone.tech
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="mt-12 pt-8 border-t border-gray-800 text-gray-400 flex flex-col md:flex-row justify-between items-center">
          <p>&copy; {new Date().getFullYear()} Iteration One. All rights reserved.</p>
          <div className="flex space-x-6 mt-4 md:mt-0">
            <Link to="/privacy-policy" className="hover:text-[#42DCDB]">
              Privacy Policy
            </Link>
            <Link to="/terms" className="hover:text-[#42DCDB]">
              Terms of Service
            </Link>
            <Link to="/sitemap" className="hover:text-[#42DCDB]">
              Sitemap
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
} 