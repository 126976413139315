import { motion, useScroll, useTransform } from 'framer-motion';
import { useRef, useEffect } from 'react';
import SEO from '../components/SEO';
import { Link } from 'react-router-dom';

const AILab = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  console.log('AILab component rendered');
  
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end start"]
  });

  const y1 = useTransform(scrollYProgress, [0, 1], [0, 150]);
  const y2 = useTransform(scrollYProgress, [0, 1], [0, -150]);

  return (
    <div ref={containerRef} className="bg-black text-white pt-24">
      <SEO 
        title="AI App Lab"
        description="From concept to production in 3-6 months, we build cutting-edge AI applications that transform businesses"
      />
      
      <section className="relative py-20 overflow-hidden">
        {/* Animated background */}
        <div className="absolute inset-0">
          <motion.div 
            className="absolute inset-0"
            animate={{
              backgroundPosition: ['0% 0%', '100% 100%'],
              transition: {
                duration: 15,
                repeat: Infinity,
                repeatType: 'reverse'
              }
            }}
            style={{
              backgroundImage: 'radial-gradient(circle at center, #42DCDB 0.5px, transparent 0.5px)',
              backgroundSize: '30px 30px',
              opacity: 0.1
            }}
          />
        </div>

        <div className="container mx-auto px-4 sm:px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <motion.div
              initial={{ scale: 0.95 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
              className="inline-block mb-6"
            >
              <div className="text-[#42DCDB] w-12 h-12">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09z" />
                </svg>
              </div>
            </motion.div>
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold mb-6">
              AI <span className="text-[#42DCDB] text-glow">App Lab</span>
            </h1>
            <p className="text-lg sm:text-xl text-gray-400 max-w-3xl mx-auto">
              From concept to production in 3-6 months, we build cutting-edge AI applications that transform businesses
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
            {aiServices.map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                className="neon-card p-6 sm:p-8 group hover:scale-[1.02] transition-transform duration-300"
              >
                <div className="text-[#42DCDB] w-10 h-10 mb-6 group-hover:scale-110 transition-transform">
                  {service.icon}
                </div>
                <h3 className="text-xl sm:text-2xl font-semibold mb-4 text-[#42DCDB]">
                  {service.title}
                </h3>
                <p className="text-gray-400 text-sm sm:text-base">
                  {service.description}
                </p>
                <motion.button
                  whileHover={{ x: 5 }}
                  className="mt-6 text-[#42DCDB] text-sm font-semibold flex items-center gap-2 group"
                >
                  Learn more 
                  <span className="group-hover:translate-x-1 transition-transform">→</span>
                </motion.button>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Floating elements */}
        <motion.div
          style={{ y: y1 }}
          className="hidden md:block absolute top-20 -left-20 w-72 h-72 parallax-layer"
        >
          <div className="w-full h-full rounded-full bg-[#42DCDB]/5 blur-3xl" />
        </motion.div>
        <motion.div
          style={{ y: y2 }}
          className="hidden md:block absolute bottom-20 -right-20 w-72 h-72 parallax-layer"
        >
          <div className="w-full h-full rounded-full bg-[#42DCDB]/5 blur-3xl" />
        </motion.div>
      </section>

      {/* Technologies Section */}
      <section className="relative py-20">
        <div className="container mx-auto px-4 sm:px-6">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-4">
              Powered by Advanced <span className="text-[#42DCDB] text-glow">AI Technologies</span>
            </h2>
            <p className="text-gray-400 text-lg">
              We leverage the latest AI technologies to build powerful solutions
            </p>
          </motion.div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-8">
            {aiTechnologies.map((tech, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="neon-card p-6 text-center hover:scale-[1.02] transition-transform duration-300"
              >
                <div className="text-[#42DCDB] w-8 h-8 mx-auto mb-4">
                  {tech.icon}
                </div>
                <h3 className="text-[#42DCDB] font-semibold mb-2">{tech.name}</h3>
                <p className="text-gray-400 text-sm">{tech.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="relative py-20 z-10">
        <div className="container mx-auto px-4 sm:px-6 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="neon-card p-8 sm:p-12 text-center max-w-4xl mx-auto relative z-10"
          >
            <h2 className="text-2xl sm:text-3xl font-bold mb-4">
              Ready to Transform Your Business with <span className="text-[#42DCDB] text-glow">AI</span>?
            </h2>
            <p className="text-gray-400 mb-8">
              Let's discuss how we can help you leverage AI to achieve your business goals
            </p>
            <Link to="/start-project" className="relative z-20">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 rounded-full bg-[#42DCDB] text-black font-semibold hover:bg-[#42DCDB]/90 transition-colors relative z-20"
              >
                Start Your Project
              </motion.button>
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Additional CTA Banner */}
      <section className="relative py-16 overflow-hidden z-10">
        <div className="absolute inset-0 bg-[#42DCDB]/5 -z-1" />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="container mx-auto px-4 sm:px-6 relative z-10"
        >
          <div className="max-w-5xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div>
                <h3 className="text-3xl font-bold mb-4">
                  Start Your AI Journey <span className="text-[#42DCDB] text-glow">Today</span>
                </h3>
                <p className="text-gray-400 mb-6">
                  From concept to production in 3-6 months, we'll help you build and deploy cutting-edge AI solutions that drive real business value.
                </p>
                <ul className="space-y-3 mb-8">
                  {[
                    'Custom AI Model Development',
                    'Seamless Integration',
                    'Rapid Prototyping',
                    'Expert Consultation'
                  ].map((item, index) => (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="flex items-center gap-3 text-gray-300"
                    >
                      <svg className="w-5 h-5 text-[#42DCDB]" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      {item}
                    </motion.li>
                  ))}
                </ul>
                <Link to="/contact" className="relative z-20">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="px-8 py-4 rounded-full border border-[#42DCDB] text-[#42DCDB] font-semibold hover:bg-[#42DCDB]/10 transition-colors relative z-20"
                  >
                    Get Started
                  </motion.button>
                </Link>
              </div>
              <div className="relative">
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  className="aspect-square relative"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-[#42DCDB]/20 to-transparent rounded-full animate-pulse" />
                  <div className="absolute inset-4 bg-gradient-to-tr from-[#42DCDB]/10 to-transparent rounded-full animate-pulse delay-300" />
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="text-[#42DCDB] w-16 h-16">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09z" />
                      </svg>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>
      </section>

      {/* Process Flow Section */}
      <section className="relative py-32 overflow-hidden">
        <div className="container mx-auto px-4 sm:px-6">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-4">
              Our <span className="text-[#42DCDB] text-glow">Process</span>
            </h2>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              From concept to deployment, we follow a streamlined process to bring your AI vision to life
            </p>
          </motion.div>

          <div className="max-w-6xl mx-auto">
            {/* Process Steps */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {processSteps.map((step, index) => (
                <motion.div
                  key={step.title}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="relative"
                >
                  {/* Connector Line */}
                  {index < processSteps.length - 1 && (
                    <div className="hidden md:block absolute top-1/2 -right-4 w-8 h-0.5 bg-gradient-to-r from-[#42DCDB]/50 to-transparent" />
                  )}
                  
                  <div className="neon-card p-6 hover:scale-[1.02] transition-transform duration-300">
                    {/* Step Number */}
                    <div className="flex items-center gap-4 mb-4">
                      <div className="w-12 h-12 rounded-full bg-[#42DCDB]/10 flex items-center justify-center">
                        <span className="text-[#42DCDB] text-xl font-bold">{index + 1}</span>
                      </div>
                      <h3 className="text-xl font-semibold text-[#42DCDB]">
                        {step.title}
                      </h3>
                    </div>
                    
                    <p className="text-gray-400 mb-4">
                      {step.description}
                    </p>

                    {/* Timeline */}
                    <div className="flex items-center gap-2 text-sm text-gray-500">
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      {step.timeline}
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>

            {/* Action Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-16">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                className="neon-card p-8"
              >
                <h3 className="text-2xl font-semibold mb-4">
                  Ready to <span className="text-[#42DCDB]">Start</span>?
                </h3>
                <p className="text-gray-400 mb-6">
                  Schedule a consultation to discuss your project and explore how we can help bring your AI vision to life.
                </p>
                <Link to="/contact">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full px-8 py-4 rounded-lg bg-[#42DCDB] text-black font-semibold hover:bg-[#42DCDB]/90 transition-colors"
                  >
                    Schedule Consultation
                  </motion.button>
                </Link>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                className="neon-card p-8"
              >
                <h3 className="text-2xl font-semibold mb-4">
                  Have <span className="text-[#42DCDB]">Questions</span>?
                </h3>
                <p className="text-gray-400 mb-6">
                  Browse our FAQ or reach out directly. We're here to help you understand how AI can transform your business.
                </p>
                <Link to="/contact">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full px-8 py-4 rounded-lg border border-[#42DCDB] text-[#42DCDB] font-semibold hover:bg-[#42DCDB]/10 transition-colors"
                  >
                    Get in Touch
                  </motion.button>
                </Link>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="relative py-32 bg-black/50">
        <div className="container mx-auto px-4 sm:px-6">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-4">
              Client <span className="text-[#42DCDB] text-glow">Success Stories</span>
            </h2>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              See how we've helped businesses transform with AI
            </p>
          </motion.div>

          <div className="max-w-6xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {testimonials.map((testimonial, index) => (
                <motion.div
                  key={testimonial.name}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="neon-card p-8 relative"
                >
                  <div className="absolute -top-6 left-8">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-12 h-12 rounded-full border-2 border-[#42DCDB]"
                    />
                  </div>
                  <div className="pt-8">
                    <p className="text-gray-400 mb-6 italic">"{testimonial.content}"</p>
                    <div className="flex items-center justify-between">
                      <div>
                        <h4 className="text-[#42DCDB] font-semibold">{testimonial.name}</h4>
                        <p className="text-sm text-gray-500">{testimonial.role}</p>
                        <p className="text-sm text-gray-500">{testimonial.company}</p>
                      </div>
                      <div className="text-[#42DCDB] w-8 h-8">
                        {testimonial.logo}
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const aiServices = [
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456z" />
      </svg>
    ),
    title: "Custom AI Models",
    description: "Build and deploy custom AI models tailored to your specific business needs and use cases."
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
      </svg>
    ),
    title: "AI Integration",
    description: "Seamlessly integrate powerful AI capabilities into your existing systems and workflows."
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
      </svg>
    ),
    title: "Rapid Prototyping",
    description: "Quick iteration and development of AI-powered features and applications."
  }
];

const aiTechnologies = [
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09z" />
      </svg>
    ),
    name: "GPT-4",
    description: "Advanced language processing and generation"
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42" />
      </svg>
    ),
    name: "DALL·E 3",
    description: "AI-powered image generation"
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
      </svg>
    ),
    name: "Whisper",
    description: "Speech recognition and transcription"
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
      </svg>
    ),
    name: "Computer Vision",
    description: "Image and video analysis"
  }
];

const processSteps = [
  {
    title: "Discovery",
    description: "We start by understanding your business needs, challenges, and goals through in-depth consultation.",
    timeline: "Week 1-2",
    icon: "🔍"
  },
  {
    title: "Design & Planning",
    description: "Our team creates a detailed project roadmap and technical architecture tailored to your requirements.",
    timeline: "Week 2-3",
    icon: "📋"
  },
  {
    title: "Development",
    description: "Rapid development with regular updates and iterations to ensure we're meeting your expectations.",
    timeline: "Week 3-10",
    icon: "⚡"
  },
  {
    title: "Testing & QA",
    description: "Rigorous testing of all features and AI models to ensure reliability and performance.",
    timeline: "Week 10-11",
    icon: "🎯"
  },
  {
    title: "Deployment",
    description: "Smooth deployment to production with zero downtime and proper monitoring setup.",
    timeline: "Week 11-12",
    icon: "🚀"
  },
  {
    title: "Support & Optimization",
    description: "Ongoing support, monitoring, and optimization of your AI solution post-launch.",
    timeline: "Week 12+",
    icon: "📈"
  }
];

const testimonials = [
  {
    name: "Anthony Oren",
    role: "CEO & Technology Partner",
    company: "Nero Consulting Inc.",
    image: "https://media.licdn.com/dms/image/v2/C5603AQH4F_1oqSqYwg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1516264149425?e=1741219200&v=beta&t=43ptb6Q970x4cYrvXi1Dn3z_K1DZs-f491cgexErO6g",
    content: "Having been technology partners for over a decade, I've witnessed firsthand how Iteration One consistently delivers cutting-edge AI solutions. Their deep expertise in AI integration and ability to transform complex requirements into elegant solutions has been instrumental in numerous successful projects.",
    logo: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z" />
      </svg>
    )
  },
  {
    name: "Mauricio Lorenzetti",
    role: "Founder",
    company: "Atlas",
    image: "https://media.licdn.com/dms/image/v2/D4E03AQFvhvW9LDYiPg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1728941935042?e=1741219200&v=beta&t=4vGP4qMTsknSI78zzy0QM2Okupd4ofU0c3Wxxh2mAKg",
    content: "The team at Iteration One delivered beyond our expectations. They understood our vision and turned complex requirements into an elegant AI solution that has revolutionized our operations.",
    logo: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
      </svg>
    )
  },
  {
    name: "Strategic Leader",
    role: "Strategic Leader",
    company: "Fortune 500 Company",
    image: "https://media.licdn.com/dms/image/v2/D4E03AQEPYd0yrjlpkQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1694798443351?e=1741219200&v=beta&t=vkwqCNr8NdHYMCHXOc77ARr_SJppFTonWl3o9mBbbPE",
    content: "What impressed me most was their ability to translate complex technical concepts into real business value. The AI implementation exceeded our expectations and delivered measurable results.",
    logo: (
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5m.75-9l3-3 2.148 2.148A12.061 12.061 0 0116.5 7.605" />
      </svg>
    )
  }
];

export default AILab; 