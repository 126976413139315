import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import SEO from '../components/SEO';

const ProjectIntake = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    projectType: '',
    description: '',
    timeline: '',
    budget: ''
  });

  // Validate form whenever formData changes
  useEffect(() => {
    const isValid = 
      formData.name.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.company.trim() !== '' &&
      formData.projectType !== '' &&
      formData.description.trim() !== '' &&
      formData.timeline !== '' &&
      formData.budget !== '';
    
    setIsFormValid(isValid);
  }, [formData]);

  const sendEmail = async (data) => {
    try {
      const { error } = await supabase.functions.invoke('send-email', {
        body: {
          to: 'gustavo.tolone@iteration-1.com',
          subject: 'New Project Submission',
          html: `
            <h2>New Project Submission</h2>
            <p><strong>Name:</strong> ${data.name}</p>
            <p><strong>Email:</strong> ${data.email}</p>
            <p><strong>Company:</strong> ${data.company}</p>
            <p><strong>Project Type:</strong> ${data.project_type}</p>
            <p><strong>Description:</strong> ${data.description}</p>
            <p><strong>Timeline:</strong> ${data.timeline}</p>
            <p><strong>Budget:</strong> ${data.budget}</p>
          `
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (error) {
        console.error('Function error:', error);
        throw error;
      }
    } catch (error) {
      console.error('Error sending email:', error);
      // Continue with form submission even if email fails
      console.warn('Continuing despite email error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    
    setIsSubmitting(true);

    try {
      // First try to save to database
      const { error: dbError } = await supabase
        .from('project_submissions')
        .insert([
          {
            name: formData.name,
            email: formData.email,
            company: formData.company,
            project_type: formData.projectType,
            description: formData.description,
            timeline: formData.timeline,
            budget: formData.budget,
            status: 'new',
            submitted_at: new Date().toISOString()
          }
        ]);

      if (dbError) {
        console.error('Database error:', dbError);
        throw dbError;
      }

      // Try to send email, but don't block on failure
      try {
        await sendEmail(formData);
      } catch (emailError) {
        console.warn('Email sending failed, but form was submitted:', emailError);
      }

      // If we get here, the form was submitted successfully
      navigate('/thank-you');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting your form. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-black text-white pt-24 min-h-screen">
      <SEO 
        title="Start Your AI Project"
        description="Tell us about your AI project and let's bring your vision to life"
      />
      
      <div className="container mx-auto px-4 sm:px-6 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-3xl mx-auto"
        >
          <h1 className="text-4xl font-bold mb-8">
            Start Your <span className="text-[#42DCDB] text-glow">AI Journey</span>
          </h1>
          
          <form onSubmit={handleSubmit} className="space-y-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <label className="block text-gray-400 mb-2">
                  Name <span className="text-[#42DCDB]">*</span>
                </label>
                <input
                  type="text"
                  required
                  className="w-full bg-gray-900 border border-gray-800 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-[#42DCDB]"
                  value={formData.name}
                  onChange={(e) => setFormData({...formData, name: e.target.value})}
                />
              </div>
              
              <div>
                <label className="block text-gray-400 mb-2">
                  Email <span className="text-[#42DCDB]">*</span>
                </label>
                <input
                  type="email"
                  required
                  className="w-full bg-gray-900 border border-gray-800 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-[#42DCDB]"
                  value={formData.email}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                />
              </div>
            </div>

            <div>
              <label className="block text-gray-400 mb-2">
                Company <span className="text-[#42DCDB]">*</span>
              </label>
              <input
                type="text"
                required
                className="w-full bg-gray-900 border border-gray-800 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-[#42DCDB]"
                value={formData.company}
                onChange={(e) => setFormData({...formData, company: e.target.value})}
              />
            </div>

            <div>
              <label className="block text-gray-400 mb-2">
                Project Type <span className="text-[#42DCDB]">*</span>
              </label>
              <select
                required
                className="w-full bg-gray-900 border border-gray-800 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-[#42DCDB]"
                value={formData.projectType}
                onChange={(e) => setFormData({...formData, projectType: e.target.value})}
              >
                <option value="">Select a project type</option>
                <option value="ai-integration">AI Integration</option>
                <option value="custom-model">Custom AI Model</option>
                <option value="automation">Process Automation</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div>
              <label className="block text-gray-400 mb-2">
                Project Description <span className="text-[#42DCDB]">*</span>
              </label>
              <textarea
                rows="4"
                required
                className="w-full bg-gray-900 border border-gray-800 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-[#42DCDB]"
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
                placeholder="Tell us about your project idea..."
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <label className="block text-gray-400 mb-2">
                  Timeline <span className="text-[#42DCDB]">*</span>
                </label>
                <select
                  required
                  className="w-full bg-gray-900 border border-gray-800 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-[#42DCDB]"
                  value={formData.timeline}
                  onChange={(e) => setFormData({...formData, timeline: e.target.value})}
                >
                  <option value="">Select timeline</option>
                  <option value="1-3">1-3 months</option>
                  <option value="3-6">3-6 months</option>
                  <option value="6+">6+ months</option>
                </select>
              </div>

              <div>
                <label className="block text-gray-400 mb-2">
                  Budget Range <span className="text-[#42DCDB]">*</span>
                </label>
                <select
                  required
                  className="w-full bg-gray-900 border border-gray-800 rounded-lg px-4 py-3 text-white focus:outline-none focus:border-[#42DCDB]"
                  value={formData.budget}
                  onChange={(e) => setFormData({...formData, budget: e.target.value})}
                >
                  <option value="">Select budget range</option>
                  <option value="10-25k">$10k - $25k</option>
                  <option value="25-50k">$25k - $50k</option>
                  <option value="50-100k">$50k - $100k</option>
                  <option value="100k+">$100k+</option>
                </select>
              </div>
            </div>

            <motion.button
              type="submit"
              disabled={!isFormValid || isSubmitting}
              whileHover={{ scale: (!isFormValid || isSubmitting) ? 1 : 1.05 }}
              whileTap={{ scale: (!isFormValid || isSubmitting) ? 1 : 0.95 }}
              className={`w-full px-8 py-4 rounded-lg bg-[#42DCDB] text-black font-semibold transition-colors ${
                !isFormValid || isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#42DCDB]/90'
              }`}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Project Details'}
            </motion.button>

            {!isFormValid && (
              <p className="text-[#42DCDB] text-sm text-center">
                Please fill in all required fields marked with *
              </p>
            )}
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default ProjectIntake; 