import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

const MobileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const menuItems = [
    { path: '/ai-lab', label: 'AI Lab' },
    { path: '/services', label: 'Services' },
    { path: '/portfolio', label: 'Portfolio' },
    { path: '/contact', label: 'Contact' }
  ];

  const menuContent = (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="md:hidden fixed inset-0 bg-black flex items-center justify-center"
          style={{ zIndex: 9998 }}
        >
          <nav className="flex flex-col items-center space-y-12">
            {menuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                onClick={() => setIsOpen(false)}
                className="text-4xl text-white hover:text-[#42DCDB] transition-colors"
              >
                {item.label}
              </Link>
            ))}
            
            <Link
              to="/start-project"
              onClick={() => setIsOpen(false)}
              className="mt-4"
            >
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-12 py-4 rounded-full bg-[#42DCDB] text-black text-2xl font-semibold"
              >
                Get Started
              </motion.div>
            </Link>
          </nav>

          {/* Background Pattern */}
          <div className="absolute inset-0 pointer-events-none -z-10">
            <div 
              className="w-full h-full opacity-5"
              style={{
                backgroundImage: 'radial-gradient(#42DCDB 1px, transparent 1px)',
                backgroundSize: '24px 24px'
              }}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  const buttonContent = (
    <button
      onClick={() => setIsOpen(!isOpen)}
      className="md:hidden fixed top-6 right-6 z-[9999] p-2"
      aria-label="Toggle menu"
    >
      <motion.div 
        animate={isOpen ? { scale: 1.2 } : { scale: 1 }}
        className="text-[#42DCDB] text-2xl"
      >
        {isOpen ? '✕' : '≡'}
      </motion.div>
    </button>
  );

  return (
    <>
      {createPortal(buttonContent, document.body)}
      {createPortal(menuContent, document.body)}
    </>
  );
};

export default MobileMenu; 