import React from 'react';
import { motion } from 'framer-motion';
import SEO from '../components/SEO';

const Services = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  const services = [
    {
      title: "Strategic Leadership and Innovation",
      items: [
        "Technology Vision & Strategy",
        "AI-First Leadership",
        "Data-Driven Decision Making"
      ]
    },
    {
      title: "Application Development & Lifecycle Management",
      items: [
        "Modern Software Development",
        "AI-Augmented Development",
        "Engineering Teams at Your Service",
        "Lifecycle Ownership"
      ]
    },
    {
      title: "AI-Powered Operations",
      items: [
        "Intelligent Automation",
        "Enhanced Troubleshooting",
        "AI-Driven Resource Allocation"
      ]
    },
    {
      title: "Technical Leadership",
      items: [
        "Cross-Departmental Collaboration",
        "Team Enablement",
        "Scaling Technical Teams",
        "AI Training"
      ]
    }
  ];

  return (
    <>
      <SEO title="vCTO Services | Iteration One" description="Comprehensive Virtual CTO services offering technical leadership, strategic planning, and AI-driven insights." />
      
      <div className="min-h-screen bg-black text-white pt-24 pb-16">
        <div className="container mx-auto px-4 sm:px-6">
          {/* Hero Section */}
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-[#42DCDB] to-blue-500 text-transparent bg-clip-text">
              CTO as a Service (vCTO)
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Providing technical leadership, strategic planning, and AI-driven insights to help organizations navigate the complexities of modern technology ecosystems.
            </p>
          </motion.div>

          {/* Services Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
            {services.map((service, index) => (
              <motion.div
                key={index}
                className="bg-gradient-to-br from-black to-gray-900 p-8 rounded-2xl border border-[#42DCDB]/10 hover:border-[#42DCDB]/30 transition-all duration-300"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
              >
                <h3 className="text-2xl font-bold mb-4 text-[#42DCDB]">{service.title}</h3>
                <ul className="space-y-3">
                  {service.items.map((item, idx) => (
                    <li key={idx} className="flex items-start">
                      <span className="text-[#42DCDB] mr-2">•</span>
                      <span className="text-gray-300">{item}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>

          {/* CTA Section */}
          <motion.div 
            className="text-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.8 }}
          >
            <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Technology Strategy?</h2>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-3 rounded-full bg-[#42DCDB] text-black font-semibold hover:bg-[#42DCDB]/90 transition-colors"
              onClick={() => window.location.href = '/contact'}
            >
              Schedule a Consultation
            </motion.button>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Services; 