import React from 'react';

const Logo = () => (
  <img 
    src="/IterationOne-Logo.png" 
    alt="Iteration One Logo" 
    style={{ width: '32px', height: '32px' }}
  />
);

export default Logo; 